<template>
  <sidebar side="left" :collapsed="collapsed">
    <div
      class="country"
      v-for="(country, country_id) in results"
      :key="country_id"
    >
      <h4 class="country-name">
        <span>{{ country.name }}</span>
      </h4>

      <div
        class="competitions"
        v-for="(competition, competition_id) in country.competitions"
        :key="competition_id"
      >
        <h6
          class="competition-name"
          v-b-toggle="`competition-${competition_id}`"
        >
          {{ competition.name }}
        </h6>

        <b-collapse :id="`competition-${competition_id}`">
          <b-list-group>
            <b-list-group-item
              href="#"
              class="match"
              v-for="match in competition.matches"
              :key="match.id"
              @click.prevent="centerMap(match)"
            >
              <div class="match-info">
                <img :src="getLogo(match.id_casa)" width="25" />
                <strong>{{ match.casa }} - {{ match.trasferta }}</strong>
                <img :src="getLogo(match.id_trasferta)" width="25" /><br />
                {{ matchDate(match) }}
              </div>
              <b-btn variant="primary" @click.stop.prevent="addMission(match)"
                >+</b-btn
              >
            </b-list-group-item>
          </b-list-group>
        </b-collapse>
      </div>
    </div>
  </sidebar>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import Sidebar from "./Sidebar.vue";

const FORMAT = "DD/MM/YYYY HH:mm";

export default {
  name: "Results",
  components: { Sidebar },
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
  },

  computed: mapGetters("geomatch", ["results"]),

  methods: {
    centerMap(match) {
      this.$store.commit("geomatch/updateCenter", {
        lat: Number(match.lat),
        lng: Number(match.lng),
      });
    },

    matchDate(match) {
      return moment(match.data).format(FORMAT);
    },

    addMission(match) {
      this.$store.commit("geomatch/setMission", match);
      this.$root.$emit("bv::show::modal", "add-mission-modal");
    },
    getLogo(id) {
      return this.$store.state.apiEndPoint + "/logo/team/" + id;
    },
  },
};
</script>

<style lang="scss" scoped>
.country-name {
  border-bottom: 1px solid #ddd;
  margin: 15px 0;
  padding-bottom: 5px;
}

.competition-name,
.match {
  cursor: pointer;
}

.list-group {
  margin-bottom: 10px;
}

.match {
  display: flex;
  align-items: center;

  .match-info {
    flex: 1;
    color: #999999;
  }
}
</style>
