<template>
  <sidebar side="right" :collapsed="collapsed">
    <b-form class="filters" @submit.prevent="onSubmit" @reset.prevent="onReset">
      <b-form-group label="Paese">
        <multiselect
          v-model="filters.country"
          :options="countries"
          placeholder="Seleziona un paese"
          label="text"
          track-by="value"
          multiple
          :loading="countriesLoading"
        />
      </b-form-group>

      <b-form-group label="Competizione">
        <multiselect
          v-model="filters.competition"
          :options="competitions"
          placeholder="Seleziona una competizione"
          label="text"
          track-by="value"
          multiple
          :loading="competitionsLoading"
        />
      </b-form-group>

      <b-form-group>
        <b-row>
          <b-col>
            <b-btn variant="success" class="w-100" v-b-modal.new-filter-modal>
              Salva questa configurazione
            </b-btn>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group label="Intervallo di viaggio">
        <b-form-group label="Da">
          <b-row>
            <b-col cols="12" lg="7" class="mb-3 mb-lg-0">
              <b-form-datepicker
                v-model="filters.from.date"
                :dark="true"
                :min="minStartDate"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
              ></b-form-datepicker>
            </b-col>
            <b-col cols="12" lg="5">
              <b-form-timepicker
                v-model="filters.from.time"
              ></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group label="A">
          <b-row>
            <b-col cols="12" lg="7" class="mb-3 mb-lg-0">
              <b-form-datepicker
                v-model="filters.to.date"
                :dark="true"
                :min="minEndDate"
                :max="maxEndDate"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
              ></b-form-datepicker>
            </b-col>
            <b-col cols="12" lg="5">
              <b-form-timepicker v-model="filters.to.time"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form-group>

      <b-form-group label="Liste scouting">
        <b-row>
          <b-col>
            <b-select
              :options="scoutingLists"
              v-model="filters.scouting_list_id"
            >
              <template slot="first">
                <option :value="null" disabled>
                  Seleziona una lista scouting
                </option>
              </template>
            </b-select>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group>
        <b-row>
          <b-col>
            <b-btn type="submit" variant="success" class="w-100">Applica</b-btn>
          </b-col>
          <b-col>
            <b-btn type="reset" variant="danger" class="w-100">Cancella</b-btn>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group label="Configurazioni">
        <b-row>
          <b-col>
            <b-select :options="configurations" v-model="filters.configuration">
              <template slot="first">
                <option :value="null" disabled>
                  Seleziona una configurazione
                </option>
              </template>
            </b-select>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group>
        <b-row>
          <b-col>
            <b-btn
              variant="success"
              class="w-100"
              @click.prevent="loadConfiguration"
              >Carica</b-btn
            >
          </b-col>
          <b-col>
            <b-btn
              variant="danger"
              class="w-100"
              @click.prevent="deleteConfiguration"
              >Elimina</b-btn
            >
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>

    <b-modal
      id="new-filter-modal"
      title="Salva configurazione filtri"
      centered
      @ok="submitConfiguration"
    >
      <b-row>
        <b-col>
          <b-form>
            <b-form-group label="Nome">
              <b-form-input placeholder="Nome" v-model="filters.name" />
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>
    </b-modal>
  </sidebar>
</template>

<script>
import axios from "axios";
import moment from "moment";
import swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import Sidebar from "./Sidebar.vue";

const FORMAT = "YYYY-MM-DD";

export default {
  name: "Filters",
  components: { Sidebar, Multiselect },
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      countries: [],
      competitions: [],
      configurations: [],
      scoutingLists: [],
      countriesLoading: false,
      competitionsLoading: false,
      filters: {
        configuration: null,
        name: "",
        country: null,
        competition: null,
        scouting_list_id: null,
        from: {
          date: moment().format(FORMAT),
          time: "00:00",
        },
        to: {
          date: moment().add(1, "week").format(FORMAT),
          time: "23:59",
        },
      },
    };
  },

  computed: {
    minStartDate() {
      return moment().format(FORMAT);
    },

    minEndDate() {
      return moment(this.filters.from.date || this.minStartDate).format(FORMAT);
    },

    maxEndDate() {
      return moment(this.minEndDate).add(1, "week").format(FORMAT);
    },
  },

  methods: {
    loadCountries() {
      this.countriesLoading = true;
      this.axiosGet("/opta/countries", (countries) => {
        this.countries = countries.map((country) => ({
          value: country.id,
          text: country.country,
        }));
        this.countriesLoading = false;
      });
    },

    loadCompetitions() {
      if (!this.filters.country) return;

      this.competitionsLoading = true;

      let ids = [];
      if (!Array.isArray(this.filters.country)) {
        ids.push(this.filters.country.value);
      } else {
        ids = this.filters.country.map((country) => country.value);
      }

      ids = ids.join(",");

      this.axiosGet("/competizioni?ids=" + ids, (competitions) => {
        this.competitions = competitions.map((competition) => ({
          value: competition.id,
          text: competition.name,
        }));
        this.competitionsLoading = false;
      });
    },

    axiosGet(path, callback) {
      return axios
        .get(path)
        .then((res) => {
          if (!Array.isArray(res.data)) return;

          callback(res.data);
        })
        .catch(() => {
          this.$store.commit("msgPush", {
            status: 0,
            msg: "Qualcosa è andato storto. Prova a ricaricare la pagina",
          });
        });
    },

    onSubmit() {
      this.$store.dispatch("geomatch/filter");
    },

    onReset() {
      this.filters.country = null;
      this.filters.competition = null;
      this.filters.from.date = moment().format(FORMAT);
      this.filters.from.time = "00:00";
      this.filters.to.date = moment().add(1, "week").format(FORMAT);
      this.filters.to.time = "23:59";
      this.filters.configuration = null;
      this.filters.scouting_list_id = null;
    },

    loadConfigurations() {
      axios
        .get("/geomatch/filters")
        .then((res) => {
          this.configurations = res.data.map((item) => ({
            text: item.name,
            value: item,
          }));
        })
        .catch((err) => {
          this.$store.commit("msgPush", {
            status: 0,
            msg: "Qualcosa è andato storto. Prova a ricaricare la pagina",
          });
        });
    },

    loadScoutingLists() {
      axios
        .get("/liste")
        .then((res) => {
          this.scoutingLists = res.data.map((item) => ({
            value: item.id,
            text: item.nome,
          }));
        })
        .catch((err) => {
          this.$store.commit("msgPush", { msg: err.message, status: 0 });
        });
    },

    submitConfiguration(evt) {
      if (!this.filters.name) evt.preventDefault();

      axios
        .post("/geomatch/filters", {
          name: this.filters.name,
          country_ids: this.filters.country
            ? this.filters.country.map((item) => item.value)
            : null,
          competition_ids: this.filters.competition
            ? this.filters.competition.map((item) => item.value)
            : null,
        })
        .then((res) => {
          this.$store.commit("msgPush", {
            status: 1,
            msg: "Configurazione creata!",
          });
          this.loadConfigurations();
        })
        .catch((err) => {
          this.$store.commit("msgPush", {
            status: 0,
            msg: "Qualcosa è andato storto. Prova a ricaricare la pagina",
          });
        });
    },

    deleteConfiguration() {
      if (!this.filters.configuration) return;

      swal
        .fire({
          title: "Sei sicuro?",
          text: "Sei sicuro di voler eliminare questa configurazione?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sono sicuro",
        })
        .then((result) => {
          if (result.value) {
            axios
              .delete(`/geomatch/filters/${this.filters.configuration.id}`)
              .then((res) => {
                this.$store.commit("msgPush", {
                  status: 1,
                  msg: "Configurazione eliminata!",
                });
                this.loadConfigurations();
              })
              .catch((err) => {
                this.$store.commit("msgPush", {
                  status: 0,
                  msg: "Qualcosa è andato storto. Prova a ricaricare la pagina",
                });
              });
          }
        });
    },

    loadConfiguration() {
      if (!this.filters.configuration) return;

      if (this.filters.configuration.countries) {
        this.filters.country = this.filters.configuration.countries.map(
          (item) => ({
            text: item.country,
            value: item.id,
          })
        );
      }

      if (this.filters.configuration.competitions) {
        this.filters.competition = this.filters.configuration.competitions.map(
          (item) => ({
            text: item.name,
            value: item.id,
          })
        );
      }
    },
  },

  watch: {
    "filters.country": function countryFilters() {
      this.loadCompetitions();
    },

    "filters.from.date": function fromDateFilters() {
      if (moment(this.filters.from.date).isAfter(this.filters.to.date)) {
        this.$set(this.filters.to, "date", this.filters.from.date);
      }
    },

    filters: {
      handler() {
        this.$store.commit("geomatch/updateFilters", this.filters);
      },
      deep: true,
    },
  },

  mounted() {
    this.loadCountries();
    this.loadConfigurations();
    this.loadScoutingLists();
    this.$store.commit("geomatch/updateFilters", this.filters);
  },
};
</script>

<style lang="scss" scoped>
.filters {
  height: 100%;
}
</style>
