<template>
  <div class="content">
    <div :class="['backdrop', loading && 'active']">
      <div class="spinner">
        <div class="cube1"></div>
        <div class="cube2"></div>
      </div>
    </div>

    <b-form-input type="text" placeholder="Cerca" ref="input" id="input" />
    <div id="map" ref="map"></div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import _ from "lodash";
import moment from "moment";
import MarkerClusterer from "@/lib/markerclusterer";
import { isMobile } from "@/lib/utils";

export default {
  name: "Map",

  computed: mapState("geomatch", [
    "leftSidebarCollapsed",
    "rightSidebarCollapsed",
    "center",
    "matches",
    "loading",
    "players",
  ]),

  methods: {
    refreshMap() {
      Vue.nextTick(() => {
        window.google.maps.event.trigger(this.$map, "resize");
      });
    },

    tryToGetLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          this.$map.setCenter(pos);
          this.$map.setZoom(13);
        });
      }
    },

    mountSearchBox() {
      const el = this.$refs.input.$el;
      this.$searchBox = new window.google.maps.places.SearchBox(el);
      this.$map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(
        el
      );
      this.refreshMap();

      this.$map.addListener("bounds_changed", () => {
        this.$searchBox.setBounds(this.$map.getBounds());
      });

      this.$searchBox.addListener("places_changed", () => {
        const places = this.$searchBox.getPlaces();

        if (places.length === 0) {
          return;
        }

        const bounds = new window.google.maps.LatLngBounds();
        places.forEach((place) => {
          if (!place.geometry) {
            return;
          }

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });

        this.$map.fitBounds(bounds);
      });
    },

    loadData() {
      this.$store.dispatch("geomatch/filter");
    },

    openInfoWindow(position, marker) {
      let html = `
        <div class="info-window" style="color:#000000">
          <h4>${position.nome}</h4>
      `;

      _.each(position.matches, (match) => {
        const date = moment(match.data).format("DD/MM/YYYY HH:mm");

        html += `
          <div style="margin-bottom: 10px">
            <img src="${this.getLogo(match.id_casa)}" width="25"></img>
            <strong>${match.casa} - ${
          match.trasferta
        }</strong><img src="${this.getLogo(
          match.id_trasferta
        )}" width="25"></img> (${match.competizione})<br />
            ${date}<br />
        `;

        const players = _.filter(
          this.players,
          (player) =>
            (player.squadra_militante &&
              player.squadra_militante.id === match.id_casa) ||
            (player.squadra_militante &&
              player.squadra_militante.id === match.id_trasferta)
        );
        _.each(players, (player) => {
          html += `- ${player.nome} ${player.cognome} `;

          if (
            player.squadra_militante &&
            player.squadra_militante.id === match.id_casa
          ) {
            html += `(${match.casa}) `;
          } else {
            html += `(${match.trasferta}) `;
          }

          html += "<br />";
        });

        html += "</div>";
      });

      const infoWindow = new window.google.maps.InfoWindow({
        content: html,
      });
      infoWindow.open(this.$map, marker);
    },
    getLogo(id) {
      return this.$store.state.apiEndPoint + "/logo/team/" + id;
    },
  },

  watch: {
    leftSidebarCollapsed() {
      this.refreshMap();
    },

    rightSidebarCollapsed() {
      this.refreshMap();
    },

    matches() {
      this.$clusterer.clearMarkers();

      const markers = this.matches.map((position) => {
        const marker = new window.google.maps.Marker({
          position: { lat: Number(position.lat), lng: Number(position.lng) },
        });

        marker.addListener("click", () => {
          this.openInfoWindow(position, marker);
        });

        return marker;
      });

      this.$clusterer.addMarkers(markers);
    },

    center() {
      if (!this.center || !this.center.lat || !this.center.lng) return;

      this.$map.panTo(this.center);
      this.$map.setZoom(13);
    },
  },

  mounted() {
    this.$map = new window.google.maps.Map(this.$refs.map, {
      zoom: 4,
      center: { lat: 41.87194, lng: 12.56738 },
      streetViewControl: false,
      mapTypeControl: false,
      gestureHandling: isMobile() ? "cooperative" : "greedy",
    });

    this.$clusterer = new MarkerClusterer(this.$map, [], {
      imagePath: "/assets/images/m/m",
    });

    this.mountSearchBox();
    this.tryToGetLocation();

    window.google.maps.event.addListenerOnce(
      this.$map,
      "bounds_changed",
      this.loadData
      // _.debounce(this.loadData, 1000, { trailing: true, leading: false }),
    );
  },
};
</script>

<style lang="scss" scoped>
.content {
  flex: 1;
  height: 100%;
  position: relative;

  #map {
    height: 100%;
  }
}

#input {
  width: 50%;
  margin-top: 10px;
}

.spinner {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.backdrop {
  display: none;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);

  &.active {
    display: flex;
    align-items: center;
  }
}

.cube1,
.cube2 {
  background-color: #fff;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
  animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
      scale(0.5);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes sk-cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  }
  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
      scale(0.5);
  }
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}
</style>
